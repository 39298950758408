<template>
  <div class="step1">
    <h2 class="text-center my-3 my-sm-5">Sign Up</h2>
    <b-form
      @submit.prevent="nextForm"
      class="w-100 needs-validation"
      novalidate
    >
      <div class="row mb-5">
        <div class="col-12 col-md-6">
          <div class="container">
            <b-form-group
              id="input-group-1"
              label="Social Security Number:"
              label-for="input-1"
            >
              <div class="inputField">
                <b-form-input
                  id="input-1"
                  type="tel"
                  v-model="form.ssn"
                  aria-describedby="input-1-live-feedback"
                  :state="validateState('ssn')"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback"
                  >Enter last 4 digits of your SSN</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="ID Number:"
              label-for="input-2"
            >
              <div class="inputField">
                <b-form-input
                  id="input-2"
                  v-model="form.idNumber"
                  aria-describedby="input-2-live-feedback"
                  :state="validateState('idNumber')"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="input-2-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
            <label>Passport</label>
            <div class="row mb-3">
              <div class="col-12 col-lg-6">
                <b-form-group id="input-group-3">
                  <div class="inputField">
                    <b-form-input
                      id="input-3"
                      placeholder="Number"
                      v-model="form.passportNumber"
                      aria-describedby="input-3-live-feedback"
                      :state="validateState('passportNumber')"
                      required
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6">
                <b-form-group id="input-group-4" class="">
                  <div class="inputField">
                    <b-form-input
                      id="input-4"
                      v-model="form.passportState"
                      placeholder="State"
                      aria-describedby="input-4-live-feedback"
                      :state="validateState('passportState')"
                      ref="passportState"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-4-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <b-form-datepicker
                  id="datepicker-1"
                  v-model="form.passportIssueDate"
                  placeholder="Issued Date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-uk"
                  aria-describedby="input-5-live-feedback"
                  :state="validateState('passportIssueDate')"
                  required
                ></b-form-datepicker>
                <b-form-invalid-feedback id="input-5-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <b-form-datepicker
                  id="datepicker-2"
                  v-model="form.passportExpireDate"
                  placeholder="Expires Date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-uk"
                  aria-describedby="input-6-live-feedback"
                  :state="validateState('passportExpireDate')"
                  required
                ></b-form-datepicker>
                <b-form-invalid-feedback id="input-6-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="container">
            <label>Drivers License</label>
            <div class="row mb-3">
              <div class="col-12 col-lg-6">
                <b-form-group id="input-group-5">
                  <div class="inputField">
                    <b-form-input
                      id="input-5"
                      placeholder="Number"
                      v-model="form.driverNumber"
                      aria-describedby="input-5-live-feedback"
                      :state="validateState('driverNumber')"
                      required
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6">
                <b-form-group id="input-group-6">
                  <div class="inputField">
                    <b-form-input
                      id="input-6"
                      v-model="form.driverState"
                      placeholder="State"
                      aria-describedby="input-6-live-feedback"
                      :state="validateState('driverState')"
                      ref="dlState"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback id="input-6-live-feedback"
                      >This field is required</b-form-invalid-feedback
                    >
                  </div>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <b-form-datepicker
                  id="datepicker-3"
                  v-model="form.driverIssueDate"
                  placeholder="Issued Date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-uk"
                  aria-describedby="input-9-live-feedback"
                  :state="validateState('driverIssueDate')"
                  required
                ></b-form-datepicker>
                <b-form-invalid-feedback id="input-9-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
              <div class="col-12 col-lg-6 mb-3">
                <b-form-datepicker
                  id="datepicker-4"
                  v-model="form.driverExpireDate"
                  placeholder="Expires Date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-uk"
                  aria-describedby="input-10-live-feedback"
                  :state="validateState('driverExpireDate')"
                  required
                ></b-form-datepicker>
                <b-form-invalid-feedback id="input-10-live-feedback"
                  >This field is required</b-form-invalid-feedback
                >
              </div>
            </div>
            <b-button
              type="submit"
              variant="primary"
              class="nextBtn w-100 mt-4 mb-3"
            >
              Next
            </b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

const axios = require("axios");
export default {
  mixins: [validationMixin],

  data() {
    return {
      form: {
        ssn: "",
        idNumber: "",
        passportNumber: "",
        passportState: "",
        passportIssueDate: "",
        passportExpireDate: "",
        driverNumber: "",
        driverState: "",
        driverIssueDate: "",
        driverExpireDate: "",
      },
    };
  },
  validations: {
    form: {
      ssn: {
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      idNumber: {},
      passportNumber: {},
      passportState: {},
      passportIssueDate: {},
      passportExpireDate: {},
      driverNumber: {},
      driverState: {},
      driverIssueDate: {},
      driverExpireDate: {},
    },
  },
  mounted() {
    // const autocomplete = new google.maps.places.Autocomplete(
    //   document.getElementById("input-4")
    // );
    // autocomplete.addListener("place_changed", () => {
    //   const place = autocomplete.getPlace();
    //   console.log(place);
    // });

    for (let ref in this.$refs) {
      console.log(this.$refs[ref]);
      console.log(ref);
      const autocomplete = new google.maps.places.Autocomplete(this.$refs[ref].$el);

      autocomplete.addListener("place_changed", () => {
        
        const place = autocomplete.getPlace();
        console.log(place.name);
      });
    }
  },
  methods: {
    // Mapdata() {
    //   // var config = {
    //   //   method: "get",
    //   //   url:
    //   //     "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=amoeba&types=establishment&location=37.76999%2C-122.44696&radius=500&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU",
    //   //   headers: {},
    //   // };
    //   axios
    //     .get(
    //       "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=amoeba&types=establishment&location=37.76999%2C-122.44696&radius=500&key=AIzaSyD09CYCGjr-h6gCq3ImZe6pnglvuCsGcMU"
    //     )
    //     .then((response) => {
    //       console.log(JSON.stringify(response.data));
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    nextForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      var storeData = this.$store.getters.getData;

      var step2Data = {
        id: storeData.id,
        mobileNo: storeData.mobileNo,
        document: [
          {
            type: "Passport",
            identity: this.form.passportNumber,
            issueDate: this.form.passportIssueDate,
            expireDate: this.form.passportExpireDate,
            state: this.form.passportState,
            country: "IN",
          },
          {
            type: "DL",
            identity: this.form.driverNumber,
            issueDate: this.form.driverIssueDate,
            expireDate: this.form.driverExpireDate,
            state: this.form.driverState,
            country: "IN",
          },
        ],
        step2Complete: true,
        twilioUserId: storeData.twilioUserId,
      };
      this.$store.dispatch("setData", step2Data);
      console.log(this.$store.getters.getData);
      axios
        .put("https://api.vaxtraxglobal.com/api/v1/user", step2Data, {
          headers: {
            role: "user",
          },
        })
        .then((result) => {
          console.log("Step 2 successfull");
          console.log(result.data);
          this.$store.dispatch("setData", result.data);

          this.$router.push("step-3");
        })
        .catch((err) => {
          console.log(err);
        });
      // this.$router.push("step-3");
    },
  },
};
</script>

<style lang="scss" scoped>
.step1 {
  width: 100%;
  height: 100%;

  .container {
    padding: 0 5vw;

    .form-group {
      font-size: 0.9rem !important;
    }
    label {
      font-size: 14px;
    }
    input {
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
      padding: 0 20px;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0 10px;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      outline: none;

      &::-ms-expand {
        display: none;
      }

      &:focus,
      &:active {
        border-color: transparent;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0),
          0 0 8px rgba(102, 175, 233, 0);
      }
    }

    .citySelect {
      width: 100%;
      border: 1px solid var(--select-border);
      border-radius: 0.25em;
      padding: 0 0.5em;
      cursor: pointer;
      line-height: 1.1;
      border-radius: 90px;
      box-shadow: 0px 5px 9px #33333326;
      border: 1px solid #ebebeb;
    }
  }

  .b-form-btn-label-control.form-control {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
    border: 1px solid #ebebeb;
  }

  .nextBtn {
    border-radius: 90px;
    box-shadow: 0px 5px 9px #33333326;
  }
}
</style>
